/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
		    
		    $('.navbar-toggle').click(function() {
			    $(this).toggleClass('open');
			    $('body').toggleClass('noscroll');
			    if($('body').hasClass('noscroll')) {
				    $('body').bind('touchmove', function(e){e.preventDefault();});
			    } else {
				    $('body').unbind('touchmove');
			    }
		    });
		    
		    var controller = new ScrollMagic.Controller();
		    
		    var smFooterPinOffset = ($('.sm-footer-pin').height()) + 30;
		    
		    var footerPin = new ScrollMagic.Scene({triggerElement: ".content-info", triggerHook: "onEnter", offset: 200 })
		    	.setTween(".sm-footer-pin", 1, {opacity: 1})
		    	.addTo(controller);
		    	
		    var footerRise = new ScrollMagic.Scene({triggerElement: ".content-info", duration: 250, triggerHook: "onEnter"})
		    	.setTween(".content-info", 1.5, {marginTop: "60px", ease: Power1.easeOut})
		    	.addTo(controller);
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        var homeController = new ScrollMagic.Controller();
        
        var benefitsProtection = new ScrollMagic.Scene({triggerElement: ".media-object-protection", duration: 0,  offset: -40})
		    	.setTween(".media-object-protection", 0.4, {opacity: 1, x: 0})
		    	.addTo(homeController);
		    
		    var benefitsNE = new ScrollMagic.Scene({triggerElement: ".media-object-ne", duration: 0,  offset: -40})
		    	.setTween(".media-object-ne", 0.4, {opacity: 1, x: 0})
		    	.addTo(homeController);
		    
		    var benefitsOptions = new ScrollMagic.Scene({triggerElement: ".media-object-options", duration: 0,  offset: -40})
		    	.setTween(".media-object-options", 0.4, {opacity: 1, x: 0})
		    	.addTo(homeController);

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
